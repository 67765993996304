import React from "react"
import {graphql, Link} from "gatsby"
import {
  ContentfulRichTextGatsbyReference,
  renderRichText,
  RenderRichTextData
} from "gatsby-source-contentful/rich-text";
import Container from "../../components/Container/Container";
import styles from "./Blog.module.scss";
import PageHeader from "../../components/PageHeader/PageHeader";
import {STATIC_TEXTS} from "../../resources/staticTexts";
import Helmet from "react-helmet";
import {richTextOptions} from "../../utils/Utils";

export interface Node {
  data: {
    target?: {
      file?: {
        url?: string,
        fileName?: string
      }
      table?:
        {
          childMarkdownRemark?: {
            html?: string
          }
        }
    }
  }
}

export const query = graphql`
  query($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      title,
      thumbnail {
        file {
          url
        }
      },
      category {
        title,
        slug
      },
      tags,
      text {
        raw,
        references {
          ... on ContentfulTable {
          contentful_id
            table {
              childMarkdownRemark {
                html
              }
            }
          },
          ... on ContentfulAsset {
          contentful_id
            file {
              url,
              fileName
            }
          }
        }
      },
      publicationDate(formatString:"DD/MM/YYYY"),
      author
    }
  }
`

interface PageContext {
  similarPosts: [{
    slug: string,
    title: string
  }]
}

interface Data {
  contentfulBlogPost: {
    tags: string[],
    title: string,
    description: string,
    category: {
      title: string,
      slug: string
    }
    thumbnail: {
      file: {
        url: string
      }
    },
    author: string,
    text: RenderRichTextData<ContentfulRichTextGatsbyReference>,
    publicationDate: string
  }
}

interface Props {
  pageContext: PageContext,
  data: Data
}

const Blog: React.FC<Props> = ({pageContext, data}) => {
  const renderSimilarPosts = () => <div className={styles.similarPostsWrapper}>
    {!!pageContext.similarPosts.length && <div>
      <p className={styles.postHeader}>
        {STATIC_TEXTS.similarPosts}
      </p>
      <ul>
        {pageContext.similarPosts.map(post => <Link to={`/blog/${post.slug}`}>
          <li>
            {post.title}
          </li>
        </Link>)}
      </ul>
    </div>}
    {!!data.contentfulBlogPost?.tags?.length &&
    <div className={styles.tags}>
      <p className={styles.postHeader}>{STATIC_TEXTS.tags}</p>
      <div>
        {data.contentfulBlogPost?.tags?.map(tag => <Link
          to={`/page/search?keyword=${tag}`}>{tag}</Link>)}
      </div>
    </div>}
  </div>;

  return <Container className={styles.container}>
    <Helmet
      title={data.contentfulBlogPost.title}
      meta={[
        {property: "og:title", content: data.contentfulBlogPost.title},
        {property: "og:image", content: data.contentfulBlogPost.thumbnail.file.url},
        {property: "og:type", content: "website"}
      ]}/>
    <PageHeader header={data.contentfulBlogPost.title}
                description={<Link to={`/page/${data.contentfulBlogPost.category.slug}`}>
                  {data.contentfulBlogPost.category.title}</Link>} reverseOrder/>
    <div className={styles.contentWrapper}>
      <div className={styles.article}>
        <p className={styles.postHeader}>
          {`${data.contentfulBlogPost.publicationDate}, ${data.contentfulBlogPost.author}`}
        </p>
        <div className={styles.postContent}>{renderRichText(data.contentfulBlogPost.text, richTextOptions())}</div>
      </div>
      {(!!pageContext?.similarPosts?.length || !!data.contentfulBlogPost?.tags?.length) && renderSimilarPosts()}
    </div>
  </Container>;
}

export default Blog
